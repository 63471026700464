/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


//MODULO ENGINE
// var functionsOnReady = [];
// var documentIsReady = false;
   
// /**
// * Avvia l'engine 
// */
// function start() {
//     return _start();
// }

// /**
// * Funzione interna di avvio dell'engine
// */
// function _start() {
//     //Config.init();
//     executeOnReady();   
// } 

// /**
// * Esegue fisicamente le azioni in coda
// */
// var executeOnReady = function() {
//     functionsOnReady.sort(compareRule);

//     $(document).ready(function() {
//         documentIsReady = true;
//         for (var i in functionsOnReady) {
//             functionsOnReady[i].action();
//         }
//     });
// }
   
// /**
// * Regola di comparazione delle priorità
// * @param  {[type]} a 
// * @param  {[type]} b 
// * @return {any}      
// */
// var compareRule = function(a,b){
//         if (a.priority > b.priority)
//             return -1;
//         else if (a.last_nom > b.last_nom)
//             return 1;
//         else
//             return 0;
    
// }
   
// /**
// * Funzione che sostituisce la classica document ready di jquery
// * @param {Function}  action Funzione di callback da eseguire
// * @param {string =   "unkown"}    code     Codice identificativo dell'azione
// * @param {number =      1}           priority Priorità nell'esecuzione, maggiore è il valore, più alta è la priorità
// */
// function onReady(action, priority) {
    
//     if (documentIsReady) {
//         action();
//     } else {
//         functionsOnReady.push({
//             action: action,
//             priority: priority
//         });
//     }
// }

// /*
// export function lazy_load_test(){
//     return import('src/modules/translator').then(Translator => {
//           log.debug("Ho caricato il Translator",Translator);
//     }).catch(error => 'An error occurred while loading the component')
// }
// */


// /**
//  *
//  * Modulo per gestione accettazione della cookie policy.
//  *
//  * @author   Andrea Dell'Orco <ado@netnext.it>
//  * @version  v 1.2
//  */

// var cookieAccepted = false;
// var cookieAcceptedTargeting = false;
// var cookieAcceptedMeasurement = false;
// var cookieAcceptedInteractions = false;
// var cookieAcceptedExperience = false;
// var functionsOnReady = [];
// var functionsOnAcceptedInteractions = [];
// var functionsOnAcceptedExperience = [];
// var functionsOnAcceptedMeasurement = [];
// var functionsOnAcceptedTargeting = [];
 

//  /**
//   * Avvia l'engine 
//   */
// function acceptStrictlyNecessary() {
//      cookieAccepted = true;
//      callbackCookie(functionsOnReady);
//  }

// function acceptInteractions() {
//      cookieAcceptedInteractions = true;
//      callbackCookie(functionsOnAcceptedInteractions);
//  }

// function acceptExperience() {
//      cookieAcceptedExperience = true;
//      callbackCookie(functionsOnAcceptedExperience);
//  }

// function acceptTargeting() {
//      cookieAcceptedTargeting = true;
//      callbackCookie(functionsOnAcceptedTargeting);
//  }

// function acceptMeasurement() {
//      cookieAcceptedMeasurement = true;
//      callbackCookie(functionsOnAcceptedMeasurement);
//  }
 

//  /**
//   * Esegue fisicamente le azioni in coda
//   */
//  var callbackCookie = function(arrayOfFunctions) {
//      arrayOfFunctions.sort(compareRule);
//      for (var i in arrayOfFunctions) {
//          arrayOfFunctions[i].action();
//      }
//  };

//  /**
//   * Regola di comparazione delle priorità
//   * @param  {[type]} a 
//   * @param  {[type]} b 
//   * @return {any}      
//   */
//  var compareRule = function(a, b) {
//      if (a.priority > b.priority) return -1;
//      else if (a.last_nom > b.last_nom) return 1;
//      else return 0;
//  };

//  /**
//   * Funzione che permette di mettere in coda le funzioni che possono essere lanciate solo ad accettazione della privacy.
//   * Questa function esegue subito l'azione in caso di cookie accettati, altrimenti mette l'azione in coda. Si consiglia di lanciarla solo ad engine ready
//   * @param {Function}  action Funzione di callback da eseguire
//   * @param {string = "unkown"}    code     Codice identificativo dell'azione
//   * @param {number = 1}           priority Priorità nell'esecuzione, maggiore è il valore, più alta è la priorità
//   */
// function onReady(action, priority) {

//      if(cookieAccepted){
//          action();
//          return;
//      }

//      if (typeof priority == "undefined") priority = 1;
//      functionsOnReady.push({
//          action: action,
//          priority: priority
//      });
//  }

// function onReadyTargeting(action, priority) {

//      if(cookieAcceptedTargeting){
//          action();
//          return;
//      }

//      if (typeof priority == "undefined") priority = 1;
//      functionsOnAcceptedTargeting.push({
//          action: action,
//          priority: priority
//      });
//  }

// function onReadyMeasurement(action, priority) {

//      if(cookieAcceptedMeasurement){
//          action();
//          return;
//      }

//      if (typeof priority == "undefined") priority = 1;
//      functionsOnAcceptedMeasurement.push({
//          action: action,
//          priority: priority
//      });
//  }

// function onReadyExperience(action, priority) {

//      if(cookieAcceptedExperience){
//          action();
//          return;
//      }

//      if (typeof priority == "undefined") priority = 1;
//      functionsOnAcceptedExperience.push({
//          action: action,
//          priority: priority
//      });
//  }


// function onReadyInteractions(action, priority) {

//      if(cookieAcceptedInteractions){
//          action();
//          return;
//      }

//      if (typeof priority == "undefined") priority = 1;
//      functionsOnAcceptedInteractions.push({
//          action: action,
//          priority: priority
//      });
//  }


// /*ES:

// Privacy.onReady(function() {
//  example_module.init();
// });

// */

// /**
// * ascolto gli eventi di accettazione
// */
// window.addEventListener('cookie_accepted_1', function (e) {

//  onReady(function () {
//      acceptStrictlyNecessary();
//  }, 100);
// });


// window.addEventListener('cookie_accepted_2', function (e) {

// onReady(function () {
//      acceptInteractions();
//  }, 100);
// });

// window.addEventListener('cookie_accepted_3', function (e) {

// onReady(function () {
//      acceptExperience();
//  }, 100);
// });

// window.addEventListener('cookie_accepted_4', function (e) {

// onReady(function () {
//      acceptMeasurement();
//  }, 100);
// });

// window.addEventListener('cookie_accepted_5', function (e) {

// onReady(function () {
//      acceptTargeting();
//  }, 100);
// });

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // $('#menu-main-menu').smartmenus({
        //   collapsibleShowFunction: function($ul, complete) { $ul.SlideDown(250, complete); }
        // });

        // $('#menu-main-menu').bind('beforeshow.smapi', function(e, menu) {
        //
        //
        //
        //   // $(menu).hide().slideDown(250, function() { /*$(menu).css('display', '');*/ });
        //   // return false
        // });

        objectFitImages();

        //////////////////////////////////////////////////////////////////////////////
        //
        // Main menu
        //
        //////////////////////////////////////////////////////////////////////////////

        function main_menu() {

          $( window ).resize( function () {
            var window_width = $(window).width(),
                main_menu = $('#main-menu-collapsed .nav'),
                submenu_speed = 200;

            function mobile_menu(elem, event) {
              if (!elem.hasClass('mobile-menu') && (event === 'init')) {
                elem.addClass('mobile-menu');

                var navigation = '<button class="btn submenu-toggle">';
                navigation += '<span class="glyphicon glyphicon-chevron-down nav-open" aria-hidden="true"></span>';
                navigation += '<span class="glyphicon glyphicon-chevron-up nav-close" aria-hidden="true"></span>';
                navigation += '</button>';

                elem.find('.menu-item-has-children > a').after(navigation);
                elem.find('.sub-menu').hide();
                elem.find('.nav-close').hide();

                var toggle = $('.submenu-toggle', elem);
                toggle.click( function() {

                  var sub_menu = $(this).nextAll('.sub-menu');
                  if (!sub_menu.hasClass('open')) {

                    // Open submenu
                    sub_menu.addClass('open').slideDown(submenu_speed);
                    $(this).find('.nav-open').hide();
                    $(this).find('.nav-close').show();

                  } else {

                    // Close submenu
                    sub_menu.removeClass('open').slideUp(submenu_speed, function() {
                      // Close children submenus
                      $(this).find('.sub-menu').removeClass('open').hide();
                      $(this).find('.nav-open').show();
                      $(this).find('.nav-close').hide();
                    });
                    $(this).find('.nav-open').show();
                    $(this).find('.nav-close').hide();
                  }

                });

              } else if (elem.hasClass('mobile-menu') && (event === 'destroy')) {
                elem.find('.submenu-toggle').remove();
                elem.removeClass('mobile-menu');
              }
            }

            if (window_width > 992) {

              // Superfish initialization
              main_menu.superfish({
                animation   : { opacity:'show', height:'show' },
                delay       : 200,
                speed       : 200,
                speedOut    : 'easeout'
              }).addClass('sf-menu').show();

              // Mobile menu destruction
              mobile_menu(main_menu, 'destroy');


            } else {
              //Superfish destruction
              main_menu.superfish('destroy')
                .removeClass('sf-menu');

              // Mobile menu initialization
              mobile_menu(main_menu, 'init');

            }




          });
        }
        main_menu();


        //////////////////////////////////////////////////////////////////////////////
        //
        // Fixed main menu + logo on scroll
        //
        //////////////////////////////////////////////////////////////////////////////

        function fixed_navbar(navbar_height) {

          $(window).scroll(function (event) {

            var scroll = $(window).scrollTop(),
                navbar = $('.navbar-default'),
                navbar_collapsed = navbar.find('.navbar-collapse'),
                header = $('header.banner'),
                window_width = $(window).width();

            if (!navbar.hasClass('navbar-fixed-top')) {
              navbar_height = navbar.outerHeight();
            }

            if ( (scroll > 250) && (window_width > 992 ) ) {
              $('#main-menu-collapsed').collapse('hide');
              header.css('margin-top', navbar_height);
              navbar.addClass('navbar-fixed-top');
              if (navbar.css('top') <= '-60px') { // See _header.scss value
                navbar.animate({
                  'top': 0
                }, 300);
              }
            } else {
              header.css('margin-top', 0);
              navbar.removeClass('navbar-fixed-top')
                    .css('top', '');
            }

          });
        }
        fixed_navbar($('.navbar-default').outerHeight());



        /* jshint ignore:start */
        function waitForWebfonts(fonts, callback) {
          var loadedFonts = 0;
          for(var i = 0, l = fonts.length; i < l; ++i) {
            (function(font) {
              var node = document.createElement('span');
              // Characters that vary significantly among different fonts
              node.innerHTML = 'giItT1WQy@!-/#';
              // Visible - so we can measure it - but not on the screen
              node.style.position      = 'absolute';
              node.style.left          = '-10000px';
              node.style.top           = '-10000px';
              // Large font size makes even subtle changes obvious
              node.style.fontSize      = '300px';
              // Reset any font properties
              node.style.fontFamily    = 'sans-serif';
              node.style.fontVariant   = 'normal';
              node.style.fontStyle     = 'normal';
              node.style.fontWeight    = 'normal';
              node.style.letterSpacing = '0';
              document.body.appendChild(node);

              // Remember width with no applied web font
              var width = node.offsetWidth;

              node.style.fontFamily = font;

              var interval;
              function checkFont() {
                // Compare current width with original width
                if(node && node.offsetWidth !== width) {
                    ++loadedFonts;
                    node.parentNode.removeChild(node);
                    node = null;
                }

                // If all fonts have been loaded
                if(loadedFonts >= fonts.length) {
                  if(interval) {
                    clearInterval(interval);
                  }
                  if(loadedFonts === fonts.length) {
                    callback();
                    return true;
                  }
                }
              }

              if(!checkFont()) {
                  interval = setInterval(checkFont, 50);
              }

            })(fonts[i]);
          }
        }
        /* jshint ignore:end */

        //Antirimbalzo
        function debouncer(func, wait, immediate) {
          var timeout;
          return function() {
            var context = this, args = arguments;
            var later = function() {
              timeout = null;
              if (!immediate) { func.apply(context, args); }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) { func.apply(context, args); }
          };
        }



        //////////////////////////////////////////////////////////////////////////////
        //
        // Resize image on VC Module "Full width image with centered title"
        //
        //////////////////////////////////////////////////////////////////////////////


        // var liquid_image_debounce = debouncer(function() {
        //   var container = $('.liquid-image'); // Contenitore
        //   container.each( function(index) {
        //     var img = $(this).find('.background-img'), // Immagine
        //         img_ratio = img.width() / img.height(), // Porporzioni immagine
        //         container_ratio = $(this).width() / $(this).height(), // Porporzioni contenitore
        //         container_positon = $(this).attr('data-position');
        //
        //     if (container_positon == null) {
        //       container_positon = 'relative';
        //     }
        //
        //     $(this).css({
        //       'position': container_positon,
        //       'overflow': 'hidden'
        //     });
        //
        //     img.css({
        //       'position': 'absolute',
        //     });
        //
        //     if (img_ratio < container_ratio) {
        //       img.css({
        //         'width': '100%',
        //         'height': 'auto',
        //         'top': '50%',
        //         'left': 'auto',
        //         'transform': 'translateY(-50%)'
        //       });
        //     } else {
        //       img.css({
        //         'width': 'auto',
        //         'height': '100%',
        //         'top': 'auto',
        //         'left': '50%',
        //         'transform': 'translateX(-50%)'
        //       });
        //     }
        //   });
        // }, 250);


        // $(window).on('resize', liquid_image_debounce );
        // $(window).on('load', liquid_image_debounce );

        //////////////////////////////////////////////////////////////////////////////
        //
        // vc-main-slider
        //
        //////////////////////////////////////////////////////////////////////////////

        $('.vc-main-slider').slick({
          lazyLoad: 'ondemand',
          dots: true,
          arrows: false,
          infinite: true,
          autoplay: true,
          touchMove: true,
          speed: 1000,
          cssEase: 'ease-out',
          useTransform: true,
        });

        var cssPropertiesAfter = {  left: '0', opacity: 1 };
        var cssPropertiesBefore = {  left: '200%', opacity: 0 };

        $('.vc-main-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
          $(this).find('[data-slick-index="'+nextSlide+'"] .wrapper').css(cssPropertiesBefore).stop(1000).animate(cssPropertiesAfter, 1000);
        });



        ///////////////////////////////////////////////////////////////////////////////
        //
        // E) VC Slider Gallery Image custom js,
        // Maintains the same images resolution on responsive
        // Add animations for captions. (img title into div "slide_caption")
        //
        ///////////////////////////////////////////////////////////////////////////////


        function slider_caption(obj, slide) {
          obj.find(".slick-slide").each (function() {
            var slide_index = $(this).attr("data-slick-index");
            if (Number(slide_index) === Number(slide)) {
              var slideCaption = $(this).find('img').attr("title");
              var gallery_container = $(this).parents('.vc-slider-gallery');
              if (slideCaption == null || slideCaption === '') {slideCaption = '&nbsp;';}
              gallery_container.find(".slide_caption").html(slideCaption);
            }
          });
        }

        $('.slider-gallery').on('init', function(slick) {
          slider_caption($(this), '0');
        });

        $('.slider-gallery').slick({

          mobileFirst: true,
          'centerMode': true,
          'variableWidth': true,
          'slidesToShow': 1,
          'slidesToScroll': 1,
          'touchMove': true,
          'autoplay': true,
          'autoplaySpeed': 5000,
          'infinite': true,
          'cssEase': 'ease-out',
          'speed': 300,
          'useTransform': true,
          'arrows': false,
          'dots': false,
          'responsive': [
              {
                'breakpoint': 480,
                'settings': {}
              },
              {
                'breakpoint': 768,
                'settings': { 'arrows': true }
              },
              {
                'breakpoint': 992,
                'settings': { 'arrows': true}
              }
            ]
        });

        $('.slider-gallery').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
          slider_caption($(this), nextSlide);
        });


        $( window ).resize( debouncer( function () {

          var window_width = $(window).width(),
              slide = $('.slider-gallery .slick-slide');
              track = $('.slider-gallery .slick-track');

          if ($(window).width() < 480) {

            slide.css({
              'width': window_width,
              'height': window_width
            });
            track.addClass('small-device');

            slide.each( function() {

              var slide_img = $(this).find('img'),
                  slide_img_width = slide_img.width(),
                  slide_img_height = slide_img.height(),
                  slide_img_ratio = slide_img_width / slide_img_height;

              if (slide_img_ratio >= 1 ) {
                slide_img
                .addClass('landscape')
                .removeClass('portrait');
              } else {
                slide_img
                .addClass('portrait')
                .removeClass('landscape');
              }

            });
          } else {

            slide.css({
              'width': 'auto',
              'height': 'auto'
            });
            track.removeClass('small-device');
          }

        })).trigger("resize");


        ///////////////////////////////////////////////////////////////////////////////
        //
        // F)
        // Slider on Left without captions
        //
        ///////////////////////////////////////////////////////////////////////////////


        $('.gallery-images').slick({

          'mobileFirst': true,
          'dots': true,
          'infinite': true,
          'speed': 300,
          'slidesToShow': 1,
          'autoplay': false,
          'autoplaySpeed': 5000,
          'arrows': false,
          'adaptiveHeight': true,
          'cssEase': 'ease-out',
          'useTransform': true,
          'responsive': [
              {
                'breakpoint': 480,
                'settings': {}
              },
              {
                'breakpoint': 768,
                'settings': {}
              },
              {
                'breakpoint': 992,
                'settings': {

                }
              }
            ]
        });

        // baguette gallery ( open to click on magnifying glass )
        baguetteBox.run('.gallery-images', {
          fullScreen: false,
        });


        ///////////////////////////////////////////////////////////////////////////////
        //
        // O)
        // Block with images into 2 or 3 columns
        // function to auto resize box header when window has a resize
        //
        ///////////////////////////////////////////////////////////////////////////////

        function set_title_gallery_height(callback) {

          if ($(window).width() > 768)
          {
            $('.vc-image-with-title-gallery .title').each(function() { $(this).css({ "min-height" : 'auto' }); });

            $('.vc-image-with-title-gallery').each(function(index) {
              var max_height = 0;
              $(this).find('.title').each(function(index_title) {
                if($(this).outerHeight() > max_height ) {
                  max_height = $(this).outerHeight();
                }
              });
              $(this).find('.title').each(function(index_title) {
                $(this).css({ "min-height" : max_height });
              });
            });
            return true;

          } else {

            $('.vc-image-with-title-gallery .title').each(function() { $(this).css({ "min-height" : 'auto' }); });

          }
        }

        $(window).load(function() {
          set_title_gallery_height();
        });

        $(window).resize(function () {
            set_title_gallery_height();
        });

        ///////////////////////////////////////////////////////////////////////////////
        //
        // H)
        // Box at a glance
        //
        ///////////////////////////////////////////////////////////////////////////////


        function set_box_at_a_glance_height(callback) {

          if ($(window).width() > 768)
          {

            $('.vc-box-at-a-glance .title').each(function() { $(this).css({ "min-height" : 'auto' }); });

            $('.vc-box-at-a-glance').each(function(index) {
              var max_height = 0;

              $(this).find('.title').each(function(index_title) {
                if($(this).outerHeight() > max_height) {
                  max_height = $(this).outerHeight();
                }
              });

              $(this).find('.title').each(function(index_title) {
                $(this).css({ "min-height" : max_height });
              });
            });
            return true;

          }else {

            $('.vc-box-at-a-glance .title').each(function() { $(this).css({ "min-height" : 'auto' }); });

          }
        }

        waitForWebfonts(['Conduit ITC W01 Light', 'Conduit ITC W01 Medium'], function() {
          set_box_at_a_glance_height();
          $('.vc-box-at-a-glance').css('visibility','visible');
        });

        $(window).resize(function () {
            set_box_at_a_glance_height();
        });

        ///////////////////////////////////////////////////////////////////////////////
        //
        // W) & I)
        // fix for alignment bug
        // inject bootstrap's clearfix div after elements
        //
        ///////////////////////////////////////////////////////////////////////////////

        function set_clearfix() {
          //used for W
          var clearfix = '<div class="clearfix hidden-xs"></div>';
          //used for I
          var clearfix2 = '<div class="clearfix visible-sm-block"></div>';
          var clearfix3 = '<div class="clearfix visible-md-block visible-lg-block"></div>';

          $('.vc-icon-descriprion-box').each(function() {

            $(this).find('.element').each(function(index) {
              if((index+1)%2 === 0) {
                $(this).after(clearfix);
              }
            });
          });

          $('.vc-top-image-logo-and-title-bottom, .references-post-element, .casestudy-post-element').each(function() {

            $(this).find('.element').each(function(index) {
              if((index+1)%2 === 0) {
                $(this).after(clearfix2);
              }
              if((index+1)%3 === 0) {
                $(this).after(clearfix3);
              }
            });
          });

        }

        set_clearfix();


        ///////////////////////////////////////////////////////////////////////////////
        //
        // U)
        // vc-sport-box-single
        //
        ///////////////////////////////////////////////////////////////////////////////


        // $( window ).resize( debouncer( function () {

        //   var container = $('.liquid-image'); // Contenitore

        //   container.each( function(index) {
        //     var img = $(this).find('.background-img'), // Immagine
        //         img_ratio = img.width() / img.height(), // Proporzioni immagine
        //         container_ratio = $(this).width() / $(this).height(); // Proporzioni contenitore

        //     $(this).css({
        //       'position': 'relative',
        //       'overflow': 'hidden'
        //     });

        //     img.css({
        //       'position': 'absolute',
        //     });

        //     if (img_ratio < container_ratio) {
        //       img.css({
        //         'width': '100%',
        //         'height': 'auto',
        //         'top': '50%',
        //         'left': 'auto',
        //         'transform': 'translateY(-50%)'
        //       });
        //     } else {
        //       img.css({
        //         'width': 'auto',
        //         'height': '100%',
        //         'top': 'auto',
        //         'left': '50%',
        //         'transform': 'translateX(-50%)'
        //       });
        //     }
        //   });

        // })); // End window resize




        ///////////////////////////////////////////////////////////////////////////////
        //
        // U)
        // 2 sport boxes into a container. liquid image background must have 50% page width
        //
        ///////////////////////////////////////////////////////////////////////////////

        function set_vc_sport_box_double() {

          if ($(window).width() > 992) {

            // Wrapper height
            $('.vc-sport-box-double').each(function() {
              var max_height = 0;
              $(this).find('.wrapper').each(function() {
                $(this).css('min-height', 'auto');
                var this_height = $(this).outerHeight();
                if (this_height > max_height) {
                  max_height = this_height;
                }
              });

              $(this).find('.wrapper').each(function() {
                $(this).css('min-height', max_height);
              });
            });

            //Background width
            var window_width = $(window).width();
            $('.vc-sport-box-double .background').css({
              'width': window_width / 2,
              'left' : ''
            });

          } else {
            // Wrapper height
            $('.vc-sport-box-double .wrapper').css({ "min-height" : 'auto' });

            //Background width
            var window_width2 = $(window).width(),
                container_width = $('.vc-sport-box-double .container').outerWidth(),
                hshift = (window_width2 - container_width) / 2;

            $('.vc-sport-box-double .background').css({
              'width': window_width2 + 20,
              'left' : -hshift - 10
            });
          }
        }

        set_vc_sport_box_double();

        $(window).resize(function () {
          set_vc_sport_box_double();
        });
        $(window).on('load', set_vc_sport_box_double );


        ///////////////////////////////////////////////////////////////////////////////
        //
        // S)
        // 3 columns into container fluid, no paddings.
        // a liquid image background under wrapper for each column. text on wrapper.
        //
        ///////////////////////////////////////////////////////////////////////////////

        function hexToRgb(hex) {
          var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
          hex = hex.replace(shorthandRegex, function(m, r, g, b) {
              return r + r + g + g + b + b;
          });
          var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
          return result ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          } : null;
        }

        function set_vc_three_background_box_bg_color() {
          var wrapper_bg_color;
          var wrapper_bg_color_rgb;
          var wrapper_bg_color_string;
          $('.vc-three-background-box').each(function() {

            $(this).find('.column').each(function() {
              wrapper_bg_color = $(this).find('.wrapper').attr('data-bg-color');

              wrapper_bg_color_rgb = hexToRgb(wrapper_bg_color);
              wrapper_bg_color_string = "rgba(" +
                wrapper_bg_color_rgb.r + ", " +
                wrapper_bg_color_rgb.g +  ", " +
                wrapper_bg_color_rgb.b + ", 0.5) ";

              $(this).find('.wrapper').css({ "background-color" : wrapper_bg_color_string });
            });
          });
        }



        var set_vc_three_background_box_size = debouncer(function() {
          if ($(window).width() > 768) {

            $('.vc-three-background-box').each(function() {

              var max_height = 0;
              $(this).find('.column .wrapper').each(function() {
                $(this).css('min-height', 'auto');
                var this_height = $(this).outerHeight();
                if (this_height > max_height) {
                  max_height = this_height;
                }
              });

              $(this).find('.column .wrapper').each(function() {
                $(this).css('min-height', max_height);
              });

            });

          } else {

            $('.vc-three-background-box .column .wrapper').css({ "min-height" : 'auto' });

          }
        }, 200);



        $(window).on('load', set_vc_three_background_box_size );
        $(window).on('resize', set_vc_three_background_box_size );
        $(window).on('load', set_vc_three_background_box_bg_color );


        ///////////////////////////////////////////////////////////////////////////////
        //
        // References Page
        // Case Study -> slick slider
        //
        ///////////////////////////////////////////////////////////////////////////////

        $('.case-study-slider').slick({
          'slidesToShow': 3,
          'slidesToScroll': 3,
          'autoplay': true,
          'autoplaySpeed': 5000,
          'infinite': true,
          'cssEase': 'ease-out',
          'speed': 300,
          'useTransform': true,
          'arrows': false,
          'dots': true,
          'responsive':
            [
              {
                'breakpoint': 480,
                'settings': {
                  'slidesToShow': 1,
                  'slidesToScroll': 1,
                }
              },
              {
                'breakpoint': 768,
                'settings': {
                  'slidesToShow': 1,
                  'slidesToScroll': 1,
                }
              },
              {
                'breakpoint': 992,
                'settings': {
                  'slidesToShow': 2,
                  'slidesToScroll': 2,
                }
              }
            ]

        });


        function history_slider() {
          function get_years(callback) {
            var history_years = [];
            $('.history-slider-content .history-el').each( function() {
              history_years.push( $(this).attr('data-year') );
            });

            var years_html = '<div class="year empty"></div>';
            years_html += '<div class="year empty"></div>';
            for (i = 0; i < history_years.length; i++) {
              years_html += '<div class="year">'+history_years[i]+'</div>';
            }
            years_html += '<div class="year empty"></div>';
            years_html += '<div class="year empty"></div>';

            $('.history-slider-years').append(years_html);

            callback(true);
          }

          get_years( function() {

            $('.history-slider-years').slick({
             'slidesToShow': 5,
             'slidesToScroll': 1,
             'speed': 500,
             'cssEase': 'ease-out',
             'useTransform': true,
             'arrows': false,
             'infinite': false,
             'asNavFor': '.history-slider-content'
            });


            $('.history-slider-content').slick({
              'mobileFirst': true,
              'dots': false,
              'speed': 500,
              'slidesToShow': 1,
              'arrows': true,
              'cssEase': 'ease-out',
              'useTransform': true,
              'infinite': false,
              'asNavFor': '.history-slider-years'
            });
          });

        }
        history_slider();


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'blog': {
      init: function() {

        function blog_masonry() {
          $('.grid').masonry({
            itemSelector: 'article',
            columnWidth: '.grid-sizer',
            percentPosition: true
          });

        }
        blog_masonry();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // $('.wpb_video_widget .iframe').hide();


  // onReadyExperience(function () {
  //   $('.wpb_video_widget iframe').show();
  // });


})(jQuery); // Fully reference jQuery after this point.
